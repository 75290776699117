.lead {
    display: flex;
    align-items: center;
}

.spacer{
    flex: 1;
}

.card{
    background-color: #1E272E !important;
    margin: .7rem;
}

.grid-item {
    color: #eeeeee;
}

.typography-caption {
    opacity: 0.7;
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-container{
    justify-content: center;
}

.button{
    font-size: 0.7rem;
    font-weight: bold;
    max-width: 40%;
}