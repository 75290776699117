.card {
    padding: 1rem;
}

.card-content {
    width: 90%;
    margin: 0 auto;
}

.item-form {
    color: #EEEEEE;
}

.button {
    flex-grow: 1;
    width: 100%;
    font-weight: bold;
}